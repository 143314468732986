import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.12/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/middleware/auth.js"),
  basic: () => import("/opt/buildhome/repo/middleware/basic.js"),
  cms: () => import("/opt/buildhome/repo/middleware/cms.js")
}