import routerOptions0 from "/opt/buildhome/repo/app/router.options.ts";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.8.5_eslint@8.51.0_sass@1.69.3_typescript@5.0.2_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions1,
...routerOptions0,
}