export const useMarketplaceStore = defineStore("marketplace", {
  state: () => ({
    exchangeRate: {
      tokenPrice: 0,
      bnbPrice: 0,
    },
    nft: {
      id: "",
      minBid: 0,
      currentMinBid: 0,
    },
  }),

  getters: {
    GET_EXCHANGE_RATE: (state) => {
      return state.exchangeRate;
    },
  },

  actions: {
    /**
     * @param {Object} options
     */
    UPDATE_NFT_TOKEN(options = {}) {
      this.nft = { ...this.nft, ...options };
    },

    /**
     * @param {Object} options
     * @param {Number} tokenPrice
     * @param {Number} bnbPrice
     */
    UPDATE_EXCHANGE_RATE({ tokenPrice, bnbPrice }) {
      this.exchangeRate = { tokenPrice, bnbPrice };
    },
  },
});
