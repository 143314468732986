<script setup>
const marketplaceStore = useMarketplaceStore();
const { $api } = useNuxtApp();
const route = useRoute();
const runtimeConfig = useRuntimeConfig();

const { data } = $api.get("exchange-rate", {
  server: false,
});

const defaultDescription = "Discover, Collect and Trade Unique NFTs Assets in the CEEK Metaverse - nft.ceek.com";

// Update exchangeRate
watch(data, (newData) => {
  marketplaceStore.UPDATE_EXCHANGE_RATE({ tokenPrice: newData.tokenPrice, bnbPrice: newData.bnbPrice });
});

const metaDescription = ref(route.meta.description || defaultDescription);

watch(
  () => route.meta,
  (newMeta) => {
    metaDescription.value = newMeta.description || defaultDescription;
  },
  { immediate: true }
);

useHead({
  titleTemplate: (Title) => {
    return Title ? `CEEK NFT | Discover, Collect, Sell - ${Title}` : "CEEK NFT | Discover, Collect, Sell";
  },
  // default meta data. Will be overwritten meta data from child component
  htmlAttrs: {
    lang: "en",
  },
  link: [{ rel: "shortcut", href: "/favicon.ico" }],
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
  ],
  meta: [
    { name: "description", content: metaDescription },
    {
      name: "twitter:title",
      content: "CEEK NFT | Discover, Collect, Sell",
    },
    { name: "twitter:card", content: route.meta.card ? `${route.meta.card}` : "summary" },
    { name: "twitter:site", content: "@CEEK NFT" },
    { name: "twitter:description", content: metaDescription },
    { name: "twitter:image", content: "/img/pegasus.webp" },
    {
      name: "twitter:url",
      content: `${runtimeConfig.public.domain}${route.fullPath}`,
    },
    {
      property: "og:title",
      content: "CEEK NFT | Discover, Collect, Sell",
    },
    { property: "og:url", content: `${runtimeConfig.public.domain}${route.fullPath}` },
    { property: "og:type", content: "image" },
    { property: "og:description", content: metaDescription },
    { property: "og:image", content: "/img/pegasus.webp" },
    { property: "og:image:width", content: "1280" },
    { property: "og:image:height", content: "720" },
    { property: "fb:app_id", content: "356238951221544" },
  ],
});
</script>

<template>
  <NuxtLayout>
    <ClientOnly>
      <Teleport to="body">
        <LazySICTheNotification />
        <LazySICTheModal />
      </Teleport>
    </ClientOnly>
    <div
      v-if="runtimeConfig.public.env !== 'production'"
      class="fixed left-1 top-16 z-50 select-none rounded-md border-2 border-red-500 bg-white/10 p-2 font-semibold capitalize text-red-500 shadow-around shadow-black/35 backdrop-blur-lg dark:shadow-[#00bbba]"
    >
      {{ runtimeConfig.public.env }}
    </div>
    <NuxtPage />
  </NuxtLayout>
</template>
